import React from 'react';
import Button from '../Button/Button';
import './FooterCta.scss';

function FooterCta() {
  return (
    <section className="section-gap bg-lightgrey">
      <div className="container">
        <h2 className="heading text-center heading-mb-50">
          Still have questions?
        </h2>

        <div className="row">
          <div className="col col-6">
            <div className="footer-cta-query-block">
              <h3 className="body-font"> Call us now</h3>

              <div className="flex flex-wrap" style={{ gap: 15 }}>
                <Button href="tel:+1 844 333 3101">
                  Aeldra&apos; U.S. Toll Free Number
                </Button>
                <Button href="tel:+918069559800">India Contact number</Button>
              </div>
            </div>
          </div>
          <div className="col col-6">
            <div className="footer-cta-query-block">
              <h3 className="body-font"> Send us a message</h3>

              <Button href="mailto:concierge@aeldra.com">
                concierge@aeldra.com
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FooterCta;
