import React, { useEffect, useState } from 'react';
import Accordion from '../components/Accordion/Accordion';
import Button from '../components/Button/Button';
import FooterCta from '../components/footerCta/FooterCta';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import '../styles/add-funds.scss';

function AddFunds() {
  const [stepTabs, setstepTabs] = useState(0);

  function changeStepTab(value) {
    return () => setstepTabs((state) => (state === value ? null : value));
  }

  useEffect(() => {
    import('tiny-slider/src/tiny-slider').then(({ tns }) => {
      const newsSliderContainer = document.querySelectorAll('.steps-slider');

      if (newsSliderContainer) {
        newsSliderContainer.forEach((slider) => {
          tns({
            container: slider,
            autoplay: false,
            autoplayButtonOutput: false,
            mouseDrag: true,
            loop: false,
            controls: true,
            controlsPosition: 'bottom',
            speed: 1000,
            nav: false,
            gutter: 0,
            responsive: {
              0: {
                items: 1,
              },
            },
          });
        });
      }
    });
  }, []);

  return (
    <Layout lightHeader>
      <SEO
        title="Add Funds to your Aeldra U.S. Bank Account | Steps"
        description="Add funds to your Aeldra U.S. account from an Indian Account by following these easy steps."
      />

      <section className="add-funds-hero black-bg">
        <div className="container">
          <h1>Give your money wings</h1>

          <p>Add funds to your Aeldra account* and then let us do the magic.</p>

          <img
            src="/img/add-funds-hero-mobile.png"
            className="visible-mobile"
            alt="add funds"
          />
          <img
            src="/img/add-funds-hero.png"
            className="hidden-mobile"
            alt="add funds"
          />
        </div>
      </section>

      <section className="section-pad step-section-main">
        <div className="container">
          <h2 className="heading text-center">
            {' '}
            Fund using an Indian Bank account
          </h2>
          <p className="subhead text-center">
            Use these instructions if your transferring money from the bank
            you're banking* with in India to your Aeldra account*.
          </p>

          <div className="tns-arrows-mobile">
            <div className="steps-slider">
              {[
                {
                  id: 0,
                  heading: <>Open your bank&apos;s net banking* webpage</>,
                  description: (
                    <p>
                      This would be the Indian bank account supporting sending
                      money to your Aeldra U.S. account*.
                    </p>
                  ),
                  image: '/img/step-from-india-1.png',
                },
                {
                  id: 1,
                  heading: (
                    <>Select the instructions from below (click and download)</>
                  ),
                  description: (
                    <p>
                      Note: Incoming transfers from these banks have been tested
                      and are supported.
                    </p>
                  ),
                  image: '/img/step-from-india-2.png',
                },
                {
                  id: 2,
                  heading: (
                    <>
                      Enter SWIFT Code and / or ABA Routing Number and your
                      personal details as necessary
                    </>
                  ),
                  description: (
                    <p>
                      Psst..SWIFT does not mean fast! It is in fact an acronym
                      for Society for Worldwide Interbank Financial
                      Telecommunication and is used to identify banks and
                      financial institutions globally.
                    </p>
                  ),
                  image: '/img/step-from-india-3.png',
                },
                {
                  id: 3,
                  heading: (
                    <>
                      Confirm that you’re within the LRS Limits ($250,000 per
                      financial year)
                    </>
                  ),
                  description: (
                    <p>
                      Note: The Liberalised Remittance Scheme (LRS) lays down
                      the guidelines for outward remittances from India. It is
                      part of the Foreign Exchange Management Act (FEMA) 1999 by
                      the Reserve Bank of India (RBI). Under the LRS, the upper
                      limit of remittance is $2,50,000 per financial year for
                      resident Indians.
                    </p>
                  ),
                  image: '/img/step-from-india-4.png',
                },
                {
                  id: 4,
                  heading: (
                    <>
                      Wait for 3-5 business days for the USD funds to reflect in
                      your Aeldra account*
                    </>
                  ),
                  description: (
                    <p>Now you're all set to unlock global possibilities!</p>
                  ),
                  image: '/img/step-from-india-5.png',
                },
              ].map(({ id, heading, description, image }) => (
                <div key={id} className="item">
                  <div className="row align-center">
                    <div className="col col-6 offset-1">
                      <div className="step-slider-content">
                        <h4 className="step-count">STEP 0{id + 1}</h4>
                        <h3>{heading}</h3>

                        {description}
                      </div>
                    </div>

                    <div className="col col-5">
                      <div className="step-slider-img text-center">
                        <img
                          src={image}
                          alt={heading}
                          style={{ maxHeight: 480 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="black-bg section-pad">
        <div className="small-container">
          <h2 className="heading text-center">Supported Banks</h2>
          <p className="subhead text-center mx-auto" style={{ maxWidth: 600 }}>
            The following banks have been tested for transfers. Just click on
            the your bank’s logo and follow the instructions.
          </p>

          <div className="supported-banks-block">
            <div className="row justify-center">
              {[
                {
                  id: 0,
                  bankName: 'HDFC Bank',
                  icon: '/img/icons/hdfc-white.svg',
                  url: 'https://netbanking.hdfcbank.com/netbanking/',
                  pdfUrl: '/pdf/hdfc_to_aeldra.pdf',
                },
                {
                  id: 1,
                  bankName: 'ICICI Bank',
                  icon: '/img/icons/icici-white.svg',
                  url: 'https://infinity.icicibank.com/corp/AuthenticationController?FORMSGROUP_ID__=AuthenticationFG&__START_TRAN_FLAG__=Y&FG_BUTTONS__=LOAD&ACTION.LOAD=Y&AuthenticationFG.LOGIN_FLAG=1&BANK_ID=ICI&ITM=nli_cms_IB_internetbanking_login_btn&_gl=1*ppe22*_ga*MTk1MDU2NDg0OC4xNjQ5MTUzMDA4*_ga_SKB78GHTFV*MTY0OTE1MzAwOC4xLjAuMTY0OTE1MzAwOC42MA',
                  pdfUrl: '/pdf/icici_to_aeldra.pdf',
                },
                {
                  id: 2,
                  bankName: 'Axis Bank',
                  icon: '/img/icons/axis-white.svg',
                  url: 'https://retail.axisbank.co.in/wps/portal/rBanking/axisebanking/AxisRetailLogin/!ut/p/a1/04_Sj9CPykssy0xPLMnMz0vMAfGjzOKNAzxMjIwNjLwsQp0MDBw9PUOd3HwdDQwMjIEKIoEKDHAARwNC-sP1o_ArMYIqwGNFQW6EQaajoiIAVNL82A!!/dl5/d5/L2dBISEvZ0FBIS9nQSEh/',
                  pdfUrl: '/pdf/axis_to_aeldra_2.pdf',
                },
                {
                  id: 3,
                  bankName: 'SBI',
                  icon: '/img/icons/sbi-white.svg',
                  url: 'https://retail.onlinesbi.com/retail/login.htm',
                  pdfUrl: '/pdf/sbi_to_aeldra_2.pdf',
                },
                {
                  id: 4,
                  bankName: 'IDFC First Bank',
                  icon: '/img/idfc-logo.png',
                  url: 'https://my.idfcfirstbank.com/login',
                  pdfUrl: '/pdf/idfc_to_aeldra_2.pdf',
                },
                {
                  id: 5,
                  bankName: 'Any Other Bank',
                  icon: '/img/icons/other-bank-white.svg',
                  url: '#!',
                  pdfUrl: '/pdf/other-banks-to-aeldra.pdf',
                },
              ].map(({ id, bankName, url, icon, pdfUrl }) => (
                <div key={id} className="col col-3">
                  <div className="single-supported-bank">
                    <div className="bank-icon">
                      <img src={icon} alt={bankName} />

                      {id === 5 ? (
                        ''
                      ) : (
                        <a href={url} target="_blank" rel="noopener noreferrer">
                          <span>
                            Get{' '}
                            <svg
                              width="12"
                              height="11"
                              fill="none"
                              viewBox="0 0 12 11">
                              <path
                                fill="#1B1A1A"
                                d="M10.346.104a.656.656 0 0 1 .656.656v6.883a.655.655 0 0 1-1.312 0V2.34L1.82 10.21a.656.656 0 0 1-.866.055l-.062-.055a.655.655 0 0 1 0-.927l7.87-7.867H3.46a.656.656 0 0 1-.652-.58L2.804.76A.655.655 0 0 1 3.46.104h6.886Z"
                              />
                            </svg>
                            <br />
                            started
                          </span>
                        </a>
                      )}
                    </div>

                    <p>{bankName}</p>
                    <a href={pdfUrl} className="download-pdf">
                      Download PDF
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-lightgrey section-pad step-section-main">
        <div className="container">
          <h2 className="heading text-center">
            For U.S. Bank A/C - How it works?
          </h2>
          <p className="subhead text-center mx-auto" style={{ maxWidth: 770 }}>
            Follow these instructions if you're transferring money from another
            U.S. Bank account* to your Aeldra account*.
          </p>
        </div>

        <div className="small-container">
          <div className="us-transfer-tabs">
            <div className="flex">
              <div className="flex-1">
                <button
                  type="button"
                  className={stepTabs === 0 ? 'active' : ''}
                  onClick={changeStepTab(0)}>
                  ACH Transfer
                </button>
              </div>
              <div className="flex-1">
                <button
                  type="button"
                  className={stepTabs === 1 ? 'active' : ''}
                  onClick={changeStepTab(1)}>
                  ACH Transfer Set Up
                </button>
              </div>

              <div className="flex-1">
                <button
                  type="button"
                  className={stepTabs === 2 ? 'active' : ''}
                  onClick={changeStepTab(2)}>
                  Wire Transfer
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div
            className={`steps-tab-slider tns-arrows-mobile ${
              stepTabs === 0 ? 'show' : ''
            }`}>
            <div className="steps-slider">
              {[
                {
                  id: 0,
                  heading: <>Open your bank’s online/mobile banking</>,
                  description: <p></p>,
                  image: '/img/ach-transfer.png',
                },
                {
                  id: 1,
                  heading: <>Choose Aeldra as the account* to transfer to</>,
                  description: <p></p>,
                  image: '/img/ach-transfer-1.png',
                },
                {
                  id: 2,
                  heading: (
                    <>
                      Wait for 1-3 business days for it to reflect in your
                      Aeldra account*
                    </>
                  ),
                  description: <p></p>,
                  image: '/img/ach-transfer-2.png',
                },
              ].map(({ id, heading, description, image }) => (
                <div key={id} className="item">
                  <div className="row align-center">
                    <div className="col col-6 offset-1">
                      <div className="step-slider-content">
                        <h4 className="step-count">STEP 0{id + 1}</h4>
                        <h3>{heading}</h3>

                        {description}
                      </div>
                    </div>

                    <div className="col col-5">
                      <div className="step-slider-img text-center">
                        <img
                          src={image}
                          alt={heading}
                          style={{ maxHeight: 480 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className={`steps-tab-slider tns-arrows-mobile ${
              stepTabs === 1 ? 'show' : ''
            }`}>
            <div className="steps-slider">
              {[
                {
                  id: 0,
                  heading: <>Open your bank&apos;s online/mobile banking</>,
                  description: <p></p>,
                  image: '/img/ach-transfer-setup.png',
                },
                {
                  id: 1,
                  heading: (
                    <>
                      Link your Aeldra account* (Your Bank will send micro
                      deposits to your Aeldra account* to set up)
                    </>
                  ),
                  description: <p></p>,
                  image: '/img/ach-transfer-setup-1.png',
                },
                {
                  id: 2,
                  heading: (
                    <>
                      Enter your personal details and the instructions from
                      below
                    </>
                  ),
                  description: (
                    <p>
                      ABA ROUTING NUMBER: 053112929 Bank Name: Blue Ridge N.A.
                      Bank Address: 17W Main St, Luray, VA 22835, USA
                    </p>
                  ),
                  image: '/img/ach-transfer-setup-2.png',
                },
                {
                  id: 3,
                  heading: (
                    <>
                      Retrieve micro deposit amounts from your Aeldra App and
                      complete the set up with your Bank
                    </>
                  ),
                  description: <p>Congratulations! The set-up is complete</p>,
                  image: '/img/ach-transfer-setup-3.png',
                },
              ].map(({ id, heading, description, image }) => (
                <div key={id} className="item">
                  <div className="row align-center">
                    <div className="col col-6 offset-1">
                      <div className="step-slider-content">
                        <h4 className="step-count">STEP 0{id + 1}</h4>
                        <h3>{heading}</h3>

                        {description}
                      </div>
                    </div>

                    <div className="col col-5">
                      <div className="step-slider-img text-center">
                        <img
                          src={image}
                          alt={heading}
                          style={{ maxHeight: 480 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div
            className={`steps-tab-slider tns-arrows-mobile ${
              stepTabs === 2 ? 'show' : ''
            }`}>
            <div className="steps-slider">
              {[
                {
                  id: 0,
                  heading: <>Open your bank&apos;s online/mobile banking</>,
                  description: <p></p>,
                  image: '/img/wire-transfer.png',
                },
                {
                  id: 1,
                  heading: <>Choose Wire Transfer</>,
                  description: <p></p>,
                  image: '/img/wire-transfer.png',
                },
                {
                  id: 2,
                  heading: (
                    <>
                      Enter your personal details and the instructions from
                      below;
                    </>
                  ),
                  description: (
                    <p>
                      ABA ROUTING NUMBER: 053112929 Bank Name: Blue Ridge N.A.
                      Bank Address: 17W Main St, Luray, VA 22835, USA
                    </p>
                  ),
                  image: '/img/wire-transfer.png',
                },
                {
                  id: 3,
                  heading: (
                    <>
                      Wait for 1 business day for it to reflect in your Aeldra
                      account*
                    </>
                  ),
                  description: <p></p>,
                  image: '/img/wire-transfer.png',
                },
              ].map(({ id, heading, description, image }) => (
                <div key={id} className="item">
                  <div className="row align-center">
                    <div className="col col-6 offset-1">
                      <div className="step-slider-content">
                        <h4 className="step-count">STEP 0{id + 1}</h4>
                        <h3>{heading}</h3>

                        {description}
                      </div>
                    </div>

                    <div className="col col-5">
                      <div className="step-slider-img text-center">
                        <img
                          src={image}
                          alt={heading}
                          style={{ maxHeight: 480 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="section-pad">
        <div className="container">
          <div className="row">
            <div className="col-md col-8 offset-3">
              <h2 className="heading text-center-mob ">We're all ears.</h2>
              <p className="subhead text-center-mob ">
                There&apos;s no such thing as a stupid question.
              </p>

              <div className="home-faq">
                <Accordion />
              </div>
              <div style={{ marginTop: 40 }}>
                <Button href="/faq/">View all FAQs</Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterCta />
    </Layout>
  );
}

export default AddFunds;
